<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
  export default {
    created() {
      let that = this
      this.$nextTick(()=>{
        uni.getEnv(function (res) {
          console.log("getEnv结果===>", res);
          if (res.miniprogram) {
            that.$store.commit("SET_ENV", true);
            console.log("===this.isMini结果===>", that.isMini);
          }
        });
      })

    }
  }
</script>

<style lang="scss">
@import "./assets/style/index.scss";

@import "~mapbox-gl/dist/mapbox-gl.css";

* {
  margin: 0px;
  padding: 0px;
  -webkit-user-select: text;
}
#app {
  font-family: mp-quote, -apple-system-font, system-ui, "Helvetica Neue",
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei",
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 800px) {
  html {
    font-size: 40px !important;
  }
  body {
    background: #ffffff !important;
    display: flex;
    justify-content: center;
  }
  #app {
    background: #f9f9f9 !important;
    max-width: 414px;
  }
}
</style>
