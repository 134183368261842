import Vue from 'vue'
import { Tab, Tabs } from 'vant';
import { Dialog } from 'vant';
import { Lazyload, Skeleton } from 'vant';
import {
    Cell, CellGroup, Field, DatetimePicker, CheckboxGroup, Icon, Picker, Tag, Button, Checkbox
    , Image as VanImage, Form, RadioGroup, Radio, Popup, Collapse, CollapseItem, Popover, NoticeBar,
    DropdownMenu, DropdownItem
} from 'vant';
import { Divider } from 'vant';
import { Empty } from 'vant';


Vue.use(Cell).use(CellGroup).use(Tag);
Vue.use(RadioGroup).use(Radio)
Vue.use(Button);
Vue.use(Form);
Vue.use(CheckboxGroup).use(Checkbox);
Vue.use(Field);
Vue.use(Icon);
Vue.use(DatetimePicker);
Vue.use(Picker).use(Popup)
Vue.use(VanImage)
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Collapse).use(CollapseItem);
Vue.use(Dialog);
Vue.use(Skeleton);
Vue.use(Lazyload);
Vue.use(Popover);
Vue.use(NoticeBar);
Vue.use(Divider);
Vue.use(Empty);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

