import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import { Toast } from 'vant'
// import wx from './utils/wxSDK'
import wx from 'weixin-js-sdk'
Vue.prototype.$wx = wx;
// 全局注册微信jsdk
// Vue.use(wx)
import { Overlay } from 'vant';

Vue.use(Overlay);


import './components'
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Vue.config.productionTip = false


Vue.prototype.$toast = Toast
Vue.prototype.$failToast = (title)=> {
  Toast.fail({
    message: title,
    icon: 'clear'
  })
}
Vue.prototype.$successToast = (title)=> {
  Toast.success({
    message: title,
    icon: 'success'
  })
}

Vue.prototype.$loading = (title)=>{
  // 自定义加载图标
  Toast.loading({
    message: title || '加载中...',
    loadingType: 'spinner',
  });
}
Vue.prototype.$clearLoading = () =>{
  Toast.clear()
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



