import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


import { getQueryString } from '@/utils/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/news',
    name: 'News',
    meta: { title: '文章内容' },
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue'),
  },
  {
    path:'/processArticle',
    name:'processArticle',
    meta:{ title:'文章内容'},
    component:()=> import(/* webpackChunkName: "about" */ '../views/ProcessArticle.vue')
  },
  {
    path:'/mainBodyProcess',
    name:'mainBodyProcess',
    meta:{ title:'文章内容'},
    component:()=> import(/* webpackChunkName: "about" */ '../views/mainBodyProcess/index.vue')
  },
  {
    path: '/notice',
    name: 'Notice',
    component: ()=> import(/* webpackChunkName: "about" */ '../views/Notice.vue'),
    meta: { title: '消息推送' }
  },{
    path: '/activity',
    name: 'Activity',
    component: ()=> import(/* webpackChunkName: "about" */ '../views/Activity.vue'),
    meta: { title: '精彩活动' }
  }, {
    path: '/agreement',
    name: 'Agreement',
    component: () => import(/* webpackChunkName: "about" */ '../views/agreement/Agreement.vue'),
    meta: { title: '服务协议通知' }
  }, {
    path: '/sign',
    name: 'Sign',
    component: () => import(/* webpackChunkName: "about" */ '../views/agreement/Sign.vue'),
    meta: { title: '服务通知' }
  }, {
    path: '/platArticle',
    name: 'PlatArticle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlatArticle.vue'),
    meta: {
      title: (function () {
        switch (getQueryString('key')) {
          case 'for_me':
            return '关于我们'
          case 'user_agreement':
            return '用户协议'
          case 'service_article':
            return '服务条款'
          case 'service_agreement':
            return '服务协议'
          case 'privacy_agreement':
            return '隐私条款'
          case 'privacy_policy':
            return '隐私政策'
          case 'invite_user':
            return '邀请好友'
          case 'coupon_explain':
            return '使用说明'
          case 'refund_policy':
            return '退款政策'
        }
      })()
    }
  },
  {
      path: '/apply', //免费申请 1.海量房源的整租、合租 2.服务平台的VIP定制
    name: 'FreeApplication',
    component: () => import('../views/informations/FreeApplication.vue'), //路由懒加载
    meta: { title: '补充申请信息' }
  }, {
    path: '/lease', //服务平台vip定制招租
    name: ' LeaseService',
    component: () => import('../views/informations/LeaseService.vue'), //路由懒加载
    meta: { title: '补充申请信息' }
  },
  {
    path: '/energy', //家庭能源开通申请、家庭网络开庭申请
    name: 'HouseholdEnergy',
    component: () => import('../views/informations/HouseholdEnergy.vue'), //路由懒加载
    meta: { title: '补充申请信息' }
  },
  {
    path: '/overseas',//服务海外带看房
    name: 'Overseas',
    component: () => import('../views/informations/Overseas.vue'), //路由懒加载
    meta: { title: '申请补充信息' },
  }, {
    path: '/student', //1.海量房源的学生公寓 2.服务平台的学生公寓
    name: 'StudentApartment',
    component: () => import('../views/informations/StudentApartment.vue'), //路由懒加载
    meta: { title: '补充申请信息' }

  }, {
    path: '/roomTypeIntroduce', //合租单间-房间类型介绍。
    name: 'RoomTypeIntroduce',
    component: () => import('../views/roomType/RoomTypeIntroduce.vue'),
    meta: { title:  '房间类型介绍' }
  },
  //服务说明
  {
    path: '/introduce', //服务介绍
    name: 'Introduce',
    component: () => import('../views/serviceNotes/Introduce.vue'), //路由懒加载
    meta: {
      title: '服务介绍'
    }
  },
  {
    path: '/case/details', //案例展示详情
    name: 'CaseDetails',
    component: () => import('../views/serviceNotes/CaseDetails.vue'), //路由懒加载
    meta: {
      title: '案例展示'
    }
  },
  {
    path: '/case', //案例展示
    name: 'CaseShow',
    component: () => import('../views/serviceNotes/CaseShow.vue'), //路由懒加载
    meta: {
      title: '案例展示'
    }
  },
  {
    path: '/policy', //费用政策
    name: 'Policy',
    component: () => import('../views/serviceNotes/Policy.vue'), //路由懒加载
    meta: {
      title: '费用政策'
    }
  },
  {
    path: '/register', //注册主页
    name: 'Register',
    component: () => import('../views/register/index.vue'), //路由懒加载
    meta: {
      title: '立即注册'
    }
  },
  {
    path: '/register/poster', //海报页
    name: 'RegisterPoster',
    component: () => import('../views/register/poster.vue'), //路由懒加载
    meta: {
      title: ''
    }
  },
  {
    path: '/register/success', //注册成功
    name: 'RegisterSuccess',
    component: () => import('../views/register/success.vue'), //路由懒加载
    meta: {
      title: '注册成功'
    }
  },
  {
    path: '/activityContent', //活动内容
    name: 'ActivityContent',
    component: () => import('../views/components/ActivityContent.vue'), //路由懒加载
    meta: {
      title: '活动说明'
    }
  },
  {
    path: '/community', //社群
    name: 'Community',
    component: () => import('../views/community/index.vue'),
    meta: { title: '加入社群' }
  },
  {
    path: '/inviteFriends', //邀请好友
    name: 'InviteFriends',
    component: () => import('../views/invite/InviteFriends.vue'),
    meta: { title: '邀请好友' }
  },
  {
    path: '/inviteRule', //邀请规则
    name: 'InviteRule',
    component: () => import('../views/invite/InviteRule.vue'),
    meta: { title: '活动规则' }
  },
  {
    path: '/myInvite', //我的邀请
    name: 'MyInvite',
    component: () => import('../views/invite/myInvite.vue'),
    meta: { title: '我的邀请' }
  },
  { path: '/pay/success', //邀请好
    name: 'PaySuccess',
    component: () => import('../views/payResult/Success.vue'),
    meta: { title: '支付成功' }
  },
  { path: '/orderPay/orderDetails', //订单详情(支付成功、待审核、待支付、驳回)
    name: 'orderDetails',
    component: () => import('../views/orderDetails/orderDetails.vue'),
    meta: { title: '订单详情' }
  },
  { path: '/problem/index', //常见问题
    name: 'problem',
    component: () => import('../views/commonProblem/Index.vue'),
    meta: { title: '常见问题' }
  },
  { path: '/problem/detail', //问题详情
    name: 'problemDetail',
    component: () => import('../views/commonProblem/Detail.vue'),
    meta: { title: '问题详情' }
  },
  { path: '/2022/earlyBird', //早鸟活动
    name: '2022-bird',
    component: () => import('../views/2022/earlyBird/index.vue'),
    meta: { title: '早鸟活动-租房预定' }
  },
  { path: '/2022/earlyBird/register', //早鸟活动
    name: '2022-bird-register',
    component: () => import('../views/2022/earlyBird/register.vue'),
    meta: { title: '早鸟活动-注册' }
  },
  { path: '/2022/earlyBirdS2', //早鸟活动
    name: '2022-birdS2',
    component: () => import('../views/2022/earlyBirdS2/index.vue'),
    meta: { title: '早鸟活动' }
  },
  { path: '/2022/earlyBirdS2/register', //早鸟活动
    name: '2022-birdS2-register',
    component: () => import('../views/2022/earlyBirdS2/register.vue'),
    meta: { title: '早鸟活动-注册' }
  },
  { path: '/recommend/property', //房源推荐
    component: () => import('../views/recommendProperty/index.vue'),
    meta: { title: '易享家房源推荐' }
  },
  { path: '/recommend/mapbox', //房源推荐 地图
    component: () => import('../views/recommendProperty/mapbox.vue'),
    meta: { title: '房源地址' }
  },
  { path: '/group/index', //拼团背景图点击页面-活动说明
    component: () => import('../views/group/index.vue'),
    meta: { title: '活动说明' }
  },
  {
    path: '/myOrderInfo', //我的订单详情
    component: () => import('../views/myOrderInfo/index.vue'),
    meta: { title: '我的订单详情'}
  },
  {
    path: '/refundOrderInfo', //我的订单详情
    component: () => import('../views/myOrderInfo/refundOrderInfo.vue'),
    meta: { title: '退款订单详情'}
  },
  {
    path:'/discountInfo',
    component:() => import('../views/discountInfo/index.vue'),
    meta: { title: '优惠详情'}
  },


  {
    path:'/messageReminder',
    component:()=>import('../views/messageReminder/index'),
    meta:{ title:'消息推送'}
  }
]
const router = new VueRouter({
  routes,
  // mode: 'history',
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  NProgress.start()
  next()
})
router.afterEach(() => {
  if (document.title) {
    try {
      changePageTitle.postMessage(JSON.stringify({
        pageTitle: document.title
      }))
    }catch (err) {
      console.info('非app环境，无需修改title')
    }
  }
  NProgress.done()
})

export default router
