<template>
  <div class="home">
    <img alt="Vue logo" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/logo.png">
  </div>
</template>

<script>

  import {Button} from 'vant'
// @ is an alias to /src
export default {
  name: 'Home',
  data() {
    return {

    }
  },
  components: {
    [Button.name]: Button,
  },
  created() {
  }
}
</script>


<style lang="scss">

</style>
